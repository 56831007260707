@media (min-width: 1024px) {
    .tabs .col-md-4 {
        float: left;
        padding-right: 3rem!important;
        padding-left: 3rem!important;
    }
    .web-logo  {
        align-items:center;
        float: left;
        display: flex;
    }
    .desktop-menu {
        justify-content: center;
        align-items:center;
        display: flex;
    }
}
@media (min-width: 501px) {
    .class-item:hover a, .class-item:hover span, .class-item:hover p {
        color: white !important;
    }
    .class-item:hover {
        -webkit-transform: scale(1.2);
        -ms-transform: scale(1.2);
        transform: scale(1.2);
        z-index: 2;
        background: #ff6600;
        color: #FFFFFF;
    }
    .tabs .col-md-4 {
        float: left;
    }
    .corporate .col-lg-6, .e-commerce .col-lg-6, .my-own .col-lg-6{
        float:left;
        padding-right: 2rem!important;
        padding-left: 2rem!important;
    }
    .companies-padding {
        padding-right: 2rem!important;
        padding-left: 2rem!important;
    }
}
@media (max-width: 768px) {
    .desktop-menu nav{
        display: none;
    }
    .mobile-menu {
        display: block;
        text-align: right;
    }
}
@media (max-width: 500px){
    #root {
        width: 100% !important;
    }
    .mini-slider-desc h3 {
        font-size: 17px;
    }
    .mobile-menu {
        margin-top: 15px;
        text-align: center;
    }
    .slider-text h4 {
        font-size: 3rem;
    }
    .slider-text p {
        font-size: 17px;
    }
    .slider-text a {
        font-size: 18px;
    }
    .tabs .col-md-4 {
        margin-bottom: 10px;
    }
    .web-logo {
        display: block !important;
        text-align: center;
    }
    .site-section h4 {
        font-size: 1.3rem;
    }
}