body{
    background-color: #f8f8f8 !important;
    font-family: 'Muli', sans-serif !important;
}
a {
    text-decoration: none !important;
}
li {
    list-style: none;
}
.float-left {
    float: left !important;
}
::-moz-selection {
    background-color: black;
    color: #fff;
}
::selection {
    background-color: black;
    color: #fff;
}
/*Header*/
.mobile-menus {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    height: 100%;
    background-color: white;
    display: none;
}
.mobile-menus ul {
    padding: 0;
}
.mobile-menus ul li {
    display: block !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    color: black !important;
}
.mobile-menus ul li a {
    color: black !important;
}

.mobile-menu {
    display: none;
}
.mobile-menu button {
    border: none;
    background-color: transparent;
    z-index: 999;
}
.mobile-menu button svg {
    color: white;
    width: 30px;
    height: 30px;
}
.scrolled {
    position: fixed;
    background-color: black;
    z-index: 999;
    transition: background-color 1s;
}
header{
    position: absolute;
    width: 100%;
    padding: 25px 0px 15px 0;
    top: 0;
    z-index: 99;
}
.mobile-menus p {
    font-size: 28px;
    font-weight: bold;
    color: black;
    margin-top: 20px;
}
.mobile-close button {
    border: none;
    background-color: transparent;
    font-size: 25px;
    font-weight: 800;
}
.mobile-menus span {
    color:#ff6600;
    font-size: 28px;
    padding-left: 10px;
}
.site-logo h1 {
    font-size: 28px;
    font-weight: bold;
    color: white;
}
.site-logo span {
    color:#ff6600;
    font-size: 28px;
    padding-left: 10px;
}
.Headers ul li a {
    color: white;
}
.Headers ul li {
    display: inline-block;
    padding: 5px 20px;
    font-size: 1.1rem;
    color: white;
    position: relative;
}
.Headers ul li a:hover {
    position: relative;
    color: #ff6600 !important;
    transition: all 1s;
}
.mobile-menu-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: black;
    padding-bottom: 20px;
}
/*Header*/
/*Slider*/
.main-slider {
    position: relative;
    background-image: url(./assets/images/slider.jpg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.main-slider:after {
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}
.slider-text {
    z-index: 99;
    color: white;
}
.slider-text h4 {
    font-size: 4rem;
    font-weight: 900;
}
.slider-text p {
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 500;
}
.slider-text a {
    padding: 10px 20px 10px 20px;
    border: 1px solid white;
    color: white;
    margin-top: 15px;
    font-size: 20px;
    font-weight: 800;
}
.slider-text a:hover {
    background: white;
    color: black;
}
/*Slider*/
/*Content*/
.site-section {
    padding: 5em 0;
    position: relative;
    display: block;
}
.site-section h4 {
    color: #000;
    font-size: 1.5rem;
    font-weight: 900;
}
.mini-slider h2 {
    font-size: 17px;

}
#mini-slider {
    margin-top: 35px;
    width: 100%;
    display: block;
    position: relative;
}
#mini-slider img {
    display: inline-block;
}
.mini-slider-desc {
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 10%) !important;
    background: white;
    padding: 30px;
    border-radius: 15px;
}
.mini-slider-desc div {
    box-sizing: border-box;
}
.mini-slider-desc h3 {
    padding-top: 25px;
    font-size: 20px;
    font-weight: 500;
}
.slick-slide {
    padding-left: 20px;
    padding-right: 20px;
}
.slick-dots li.slick-active button:before {
    color: #ff6600 !important;
}
.slick-dots li button:before{
    color: #ff6600 !important;
}
.software-skills {
    margin-top: 50px !important;
    position: relative;
    display: block;
}
.software-skills h4 {
    font-weight: 900;
    font-size: 1.5rem;
}
.software-skills .skillbar-title:nth-child(2) {
    background: black;
}
/*Content*/
/*works*/
.works {
    height: 500px;
    position: relative;
    background-image: url(./assets/images/oguzhansengulcom-companies-work.jpg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size:cover;
}
.works:after {
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}
.class-item {
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 10%);
    border-radius: 5px;
    overflow: hidden;
    transition: .3s all ease;
    box-sizing: border-box;
    margin-bottom: 30px;
}
.class-item a, .class-item p {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 120px;
    flex: 0 0 120px;
    color: black;
    font-size: 18px;
    text-align: left;
}
.class-item span{
    font-size: 16px;
    color: #aeaeae;
    line-height: 1.7;
    padding-right: 15px;
    float: left;
}


.class-item-text {
    padding-left: 15px;
}
/*Works*/
/*Projects*/
.projects {
    position: relative;
    background-image: url(./assets/images/oguzhansengulcom-projects.jpg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size:cover;
}
.projects:after {
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}
.col-description {
    line-height: 1.7;
    font-weight: 300;
    font-size: 1.1rem;
    color: #989898;
}
.tabs a {
    background: #eee;
    padding: 10px;
    display: block;
    color: #ff6600;
    font-weight: 700;
    border-radius: 15px;
}
.tabs a:hover {
    background-color: #ff6600;
    color: white;
    transition: background-color 1s;
    transition: color 1s;
}
.tabs .col-md-4 {
    padding-left: 15px;
    padding-right: 15px;
}
.tabsActive {
    background-color: #ff6600 !important;
    color: white !important;
}
.companies-padding img {
    width: 100%;
}
.class-item a {
    width: 114px;
    height: 114px;
    display: flex
;
    align-items: center;
}
.moreDetail {
    height: 370px;
    overflow: hidden;
}
.more {
    margin-top: 20px !important;
}
.more button {
    background: black;
    color: white;
    border-radius: 20px;
    padding: 5px 40px;
    border: 1px solid black;
}
.more button:hover {
    background: white;
    color: black;
    transition: all 1s;
}
/*Projects*/
/*livein*/
.livein {
    position: relative;
    background-image: url(./assets/images/oguzhansengulcom.jpg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size:cover;
}
.livein:after {
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}
/*livein*/
/*Footer*/
.contact-form label {
    display: block;
}
.contact-form input, .contact-form textarea{
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #ff6600;
    background: #eaeaea;
    border-radius: 15px;
    padding: 10px 20px 10px 20px;
    margin-bottom: 20px;
}
.form-submit {
    background-color: black !important;
    border: none !important;
    color: white;
    font-size: 20px;
    font-weight: 800;
}
.form-submit:hover {
    background-color: white !important;
    border: 1px solid black !important;
    color: black;
    transition: background-color 1s;
}
#contact {
    padding-bottom: 5em;
}
.btn-disable {
    cursor: not-allowed;
    pointer-events: none;
}
.modal-img img {
    width: 200px;

}
#form-checked {
    position: relative;
}
#form-checked span {
    position: absolute;
    border: 1px solid black;
    padding: 8px;
    width: 5px;
    height: 5px;
    display: inline-block;
    margin-left: 8px;
    margin-top: 5px;
}
.skillbar-percent {
    color:black !important;
    font-weight: 800;
}
#form-checked img {
    width: 30px;
    opacity: 0;
    margin-right: 15px;
}
#form-checked a {
    border-radius: 15px;
    cursor: pointer;
    font-weight: 900;
    border: 1px solid black;
    background: white;
    color: black !important;
    padding: 10px 10px 10px 20px;
}
.ReactModalPortal button {
    border: none;
    background-color: transparent;
    font-weight: 900;
    font-size: 20px;
}
footer {
    background-color: black;
    padding-top: 25px;
    padding-bottom: 25px;

}
.email-title {
    color: #343a40!important;
    font-size: 1.75rem;
}
.email a {
    color: #ff6600;
    font-size:1rem ;
    font-weight: 700;
}
.email a:hover {
    color: white;
    transition: color 1s;
}
.linkedin svg, .medium svg {
    color: white !important;
    font-size: 1.1rem;
}
.medium {
    width: 20px;
    float: left;
    margin-left: 10px;
    margin-right: 10px;
}
.linkedin {
    width: 20px;
    float: left;
    margin-left: 10px;
    margin-right: 10px;
}
.github {
    width: 20px;
    float: left;
    margin-left: 10px;
    margin-right: 10px;
}
.github img {
    width: 25px;
}
.copyright p {
color: #989898;
    font-size: 1rem;
}

/*Footer*/

